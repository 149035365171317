import React, { type FC, memo } from 'react'
import { DescriptionQuestionViewer } from '../DescriptionQuestionViewer'
import { useStyles } from './useStyles'
import { type Question, UserQuestionType } from '../types'
import { AnswerInput } from './AnswerInput'
import { Type, TypeVariants } from '../../Typography'

interface QuestionInputProps {
  question: Question
  placeholderText?: string
}

export const QuestionInput: FC<QuestionInputProps> = memo(
  ({ question, placeholderText }: QuestionInputProps) => {
    const classes = useStyles()
    const isDescriptionQuestion =
      question.userQuestionType === UserQuestionType.Description
    return (
      <div className={classes.content}>
        <div className={classes.title}>
          <div className={classes.titleText}>
            {isDescriptionQuestion && (
              <DescriptionQuestionViewer question={question} />
            )}
            {!isDescriptionQuestion && (
              <Type span variant={TypeVariants.h5}>
                {question.title}
                {question.questionConfig?.mandatory === true && (
                  <span className={classes.asterisk}>*</span>
                )}
              </Type>
            )}
          </div>
        </div>
        <AnswerInput question={question} placeholderText={placeholderText} />
      </div>
    )
  },
  (prev, next) => prev.question.id === next.question.id,
)

QuestionInput.displayName = 'QuestionInput'
