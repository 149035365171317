import React, { createContext, useContext } from 'react'
import { FormMode } from '../../../enums'
import { type FormSettingsContextProps, type FormSettingsContextInterface } from './types'

// todo change name to static settings context
const initialSettingsContext = {
  mode: FormMode.FormPreview,
  buttonStyle: undefined,
  labels: {
    mandatory_error_text: 'This field is required',
    pagination_currently_shown_items_info: () => '',
    submit_button: 'Submit',
    invalid_error_text: 'This field is invalid',
  },
  NL: false,
}
const FormSettingsContext = createContext<FormSettingsContextInterface>(
  initialSettingsContext,
)
const FormSettingsContextProvider = ({
  children,
  buttonStyle = 'contained',
  mode,
  NL,
  labels,
}: FormSettingsContextProps): JSX.Element => {
  return (
    // @ts-expect-error FIXME: fix FormSettingsContextProps using Partial<FormSettingsContextInterface> in types.ts
    <FormSettingsContext.Provider value={{ mode, NL, labels, buttonStyle }}>
      {children}
    </FormSettingsContext.Provider>
  )
}

const useFormSettingsContext = (): FormSettingsContextInterface =>
  useContext(FormSettingsContext)

export { FormSettingsContextProvider, useFormSettingsContext }
