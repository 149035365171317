import classnames from 'classnames'
import { isEmpty, isNil, toString } from 'lodash/fp'
import React, { type ChangeEvent, type FC } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormMode } from '../../../enums'
import { getInputErrorMessage, isValidInputValue } from '../../../utils'
import { spacing } from '../../../utils/style-guide'
import { ComboBox, type OptionProps } from '../../ComboBox'
import { InputField } from '../../InputField'
import { SliderInput } from '../../SliderInput'
import { useFormContextUpdater, useFormSettingsContext } from '../contexts'
import { default_slider_config } from '../helpers'
import { UserQuestionType, type AnswerValue, type Question } from '../types'
import { CheckboxList, type CheckboxOption } from './CheckboxList'
import { RadioGroup, type RadioGroupOption } from './RadioGroup'
import { useStyles } from './useStyles'
import { validateNumberTypeQuestion } from './validate/validateNumberTypeQuestion'
import { validateMultiSelectTypeQuestion } from './validate/validateMultiSelectTypeQuestion'
import { validateDateTypeQuestion } from './validate/validateDateTypeQuestion'
import { getMinValueForDateInput } from './helpers/getMinValueForDateInput'
import { getMaxValueForDateInput } from './helpers/getMaxValueForDateInput'
import { getMinValueForNumberInput } from './helpers/getMinValueForNumberInput'
import { getMaxValueForNumberInput } from './helpers/getMaxValueForNumberInput'
import { validateEmailTypeQuestion } from './validate/validateEmailTypeQuestion'
import { getPreviewableIcd10Codes } from './helpers/getPreviewableIcd10Codes'

interface AnswerInputProps {
  question: Question
  placeholderText?: string
}

export const AnswerInput: FC<AnswerInputProps> = ({
  question: { userQuestionType, id: questionId, questionConfig, options },
  placeholderText,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    onFormChange,
    formMethods: { errors, control },
  } = useFormContextUpdater()
  // FIXME: Temporary fix to enable Dutch language responses to Yes/No questions
  const { labels, mode, NL } = useFormSettingsContext()
  const disabled =
    mode === FormMode.ReadOnly || mode === FormMode.PatientReadOnly
  const yesOrNoOptions = [
    {
      id: '1',
      label: 'Yes',
      value: 1,
    },
    {
      id: '2',
      label: 'No',
      value: 0,
    },
  ]

  // FIXME: Temporary fix to enable Dutch language responses to Yes/No questions
  const yesOrNoOptionsNL = [
    {
      id: '1',
      label: 'Ja',
      value: 1,
    },
    {
      id: '2',
      label: 'Nee',
      value: 0,
    },
  ]

  /**
   * When a form input value is changed we need to:
   * - trigger input validation (handled by form hook through input onChange)
   * - recompute questions visibility (handled by form context through onFormChance)
   */
  const handleChange =
    (onChange: (...event: Array<unknown>) => void) => (value: AnswerValue) => {
      onChange(value)
      onFormChange()
    }

  switch (userQuestionType) {
    case UserQuestionType.MultipleSelect:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue={[]}
          rules={{
            validate: (value: Array<string>): string | boolean => {
              if (questionConfig?.mandatory === true && isEmpty(value)) {
                // TFunctionResult can't be cast to string due to eslint
                return `${t('input_error_mandatory')}`
              }
              return validateMultiSelectTypeQuestion(questionConfig, value)
            },
          }}
          render={({ onChange, onBlur, value }) => {
            if (questionConfig?.use_select === true) {
              const comboOptions: Array<OptionProps> = (options ?? []).map(
                option => ({
                  label: option.label,
                  value: option.value.toString(),
                }),
              )
              const selectedOptions: Array<OptionProps> = comboOptions.filter(
                option => value.includes(Number(option.value)),
              )
              return (
                <ComboBox
                  id={questionId}
                  key={questionId}
                  options={comboOptions}
                  multiple
                  compact
                  onChange={(
                    options: Array<OptionProps> | OptionProps | null,
                  ) => {
                    if (Array.isArray(options)) {
                      handleChange(onChange)(
                        options.map(option => Number(option.value)),
                      )
                    }
                  }}
                  onBlur={onBlur}
                  disabled={disabled}
                  selectedOptions={selectedOptions}
                  placeholder={placeholderText}
                  error={!isNil(getInputErrorMessage(errors, questionId))}
                  errorMessage={getInputErrorMessage(errors, questionId)}
                  inputStyle={{ marginBottom: spacing.xxs }}
                />
              )
            }
            const parseSelectedOptions = (): Array<string | number> => {
              if (!isNil(value)) {
                if (Array.isArray(value)) return value
                try {
                  const selectedOptions = JSON.parse(value as string)
                  if (Array.isArray(selectedOptions)) return selectedOptions
                } catch (err) {}
              }
              return []
            }
            return (
              <CheckboxList
                options={options as Array<CheckboxOption>}
                // @ts-expect-error FIXME types
                onChange={handleChange(onChange)}
                onBlur={onBlur}
                disabled={disabled}
                value={parseSelectedOptions()}
                name={questionId}
                error={getInputErrorMessage(errors, questionId)}
              />
            )
          }}
        />
      )
    case UserQuestionType.MultipleChoice:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{ required: questionConfig?.mandatory }}
          render={({ onChange, onBlur, value }) => {
            if (questionConfig?.use_select === true) {
              const comboOptions: Array<OptionProps> = (options ?? []).map(
                option => ({
                  label: option.label,
                  value: option.value.toString(),
                }),
              )
              const selectedOption: OptionProps | undefined = comboOptions.find(
                option => option.value.toString() === value.toString(),
              )
              return (
                <ComboBox
                  id={questionId}
                  key={questionId}
                  options={comboOptions}
                  compact
                  onChange={(
                    option: Array<OptionProps> | OptionProps | null,
                  ) => {
                    if (!Array.isArray(option) && option) {
                      handleChange(onChange)(Number(option.value))
                    }
                  }}
                  onBlur={onBlur}
                  disabled={disabled}
                  selectedOption={selectedOption}
                  placeholder={placeholderText}
                  error={!isNil(getInputErrorMessage(errors, questionId))}
                  errorMessage={getInputErrorMessage(errors, questionId)}
                  inputStyle={{ marginBottom: spacing.xxs }}
                />
              )
            }
            return (
              <RadioGroup
                options={options as Array<RadioGroupOption>}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleChange(onChange)(event.target.value)
                }}
                onBlur={onBlur}
                disabled={disabled}
                value={value}
                name={questionId}
                error={getInputErrorMessage(errors, questionId)}
              />
            )
          }}
        />
      )
    case UserQuestionType.Number: {
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{
            required: questionConfig?.mandatory,
            validate: (value: string): string | boolean => {
              return validateNumberTypeQuestion(questionConfig, value)
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <InputField
              type='number'
              onChange={handleChange(onChange)}
              onBlur={onBlur}
              disabled={disabled}
              className={classnames(
                classes.dateAndNumberInputWidth,
                classes.formControl,
              )}
              value={value}
              error={getInputErrorMessage(errors, questionId)}
              placeholder={placeholderText}
              compact
              hideLabel
              withErrorSpace
              min={getMinValueForNumberInput(questionConfig?.number)}
              max={getMaxValueForNumberInput(questionConfig?.number)}
            />
          )}
        />
      )
    }
    case UserQuestionType.YesNo:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{ required: questionConfig?.mandatory }}
          render={({ onChange, onBlur, value }) => (
            <RadioGroup
              // FIXME: Temporary fix to enable Dutch language responses to Yes/No questions
              options={NL === true ? yesOrNoOptionsNL : yesOrNoOptions}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleChange(onChange)(event.target.value)
              }}
              onBlur={onBlur}
              disabled={disabled}
              value={value}
              name={questionId}
              error={getInputErrorMessage(errors, questionId)}
            />
          )}
        />
      )
    case UserQuestionType.Date:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{
            required: questionConfig?.mandatory,
            validate: (value: string): string | boolean => {
              return validateDateTypeQuestion(questionConfig, value)
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <InputField
              type='date'
              onChange={handleChange(onChange)}
              onBlur={onBlur}
              disabled={disabled}
              className={classnames(
                classes.dateAndNumberInputWidth,
                classes.formControl,
              )}
              value={value}
              error={getInputErrorMessage(errors, questionId)}
              placeholder={placeholderText}
              compact
              hideLabel
              withErrorSpace
              // @ts-expect-error FIXME types
              min={getMinValueForDateInput(questionConfig?.date)}
              // @ts-expect-error FIXME types
              max={getMaxValueForDateInput(questionConfig?.date)}
            />
          )}
        />
      )
    case UserQuestionType.ShortText:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{ required: questionConfig?.mandatory }}
          render={({ onChange, onBlur, value }) => {
            // we don't use ShortText with possible value in our forms
            // but we do need it for baseline info data points
            // so until we have separate form for baseline info,
            // or question type that support multiple string options, we need this branch here
            if (!isNil(options) && !isEmpty(options)) {
              const dropdownOptions = options.map(option => ({
                label: option.label,
                value: toString(option.value),
              }))
              const isSelectedOption = (option: { value: string }): boolean =>
                option.value === value
              const selectedOption = dropdownOptions.find(isSelectedOption)
              return (
                <ComboBox
                  options={dropdownOptions}
                  onChange={(
                    option: Array<OptionProps> | OptionProps | null,
                  ) => {
                    if (!Array.isArray(option) && option) {
                      handleChange(onChange)(option.value)
                    }
                  }}
                  onBlur={onBlur}
                  selectedOption={selectedOption}
                  id={`question-${questionId}`}
                  compact
                  error={errors[questionId]}
                  errorMessage={labels.mandatory_error_text}
                />
              )
            }
            return (
              <InputField
                onChange={handleChange(onChange)}
                onBlur={onBlur}
                disabled={disabled}
                className={classes.shortAndLongInputField}
                value={value}
                error={getInputErrorMessage(errors, questionId)}
                placeholder={placeholderText}
                compact
                hideLabel
                withErrorSpace
              />
            )
          }}
        />
      )
    case UserQuestionType.LongText:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{ required: questionConfig?.mandatory }}
          render={({ onChange, onBlur, value }) => (
            <InputField
              onChange={handleChange(onChange)}
              onBlur={onBlur}
              disabled={disabled}
              className={classes.shortAndLongInputField}
              value={value}
              error={getInputErrorMessage(errors, questionId)}
              placeholder={placeholderText}
              multiline
              rows='5'
              hideLabel
              withErrorSpace
            />
          )}
        />
      )
    case UserQuestionType.MultipleChoiceGrid:
      return <div />
    case UserQuestionType.Slider: {
      const sliderConfig = questionConfig?.slider ?? default_slider_config
      const {
        min,
        max,
        step_value: stepValue,
        min_label: minlabel,
        max_label: maxlabel,
        display_marks: displayMarks,
        is_value_tooltip_on: isValueTooltipOn,
        show_min_max_values: showMinMaxValues,
      } = sliderConfig
      return (
        <div className={classes.slider}>
          <Controller
            name={questionId}
            control={control}
            rules={{ required: questionConfig?.mandatory }}
            render={({ onChange, value }) => (
              <SliderInput
                id={questionId}
                value={value}
                onChange={value => {
                  handleChange(onChange)(value)
                }}
                disabled={disabled}
                min={min}
                max={max}
                step={stepValue}
                minLabel={minlabel}
                maxLabel={maxlabel}
                marks={displayMarks}
                valueTooltipMode={isValueTooltipOn}
                showMinMaxValues={showMinMaxValues}
              />
            )}
          />
        </div>
      )
    }
    case UserQuestionType.Signature:
      return <div />
    case UserQuestionType.Telephone:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{
            required: questionConfig?.mandatory,
            validate: value =>
              isValidInputValue({
                inputRequired: questionConfig?.mandatory,
                inputType: 'tel',
                inputValue: value,
              }),
          }}
          render={({ onChange, onBlur, value }) => (
            <InputField
              id={questionId}
              type='tel'
              onChange={handleChange(onChange)}
              onBlur={onBlur}
              disabled={disabled}
              className={classes.shortAndLongInputField}
              value={value}
              error={getInputErrorMessage(errors, questionId)}
              placeholder={placeholderText}
              compact
              hideLabel
              withErrorSpace
              phoneConfig={questionConfig?.phone}
            />
          )}
        />
      )
    case UserQuestionType.Email:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{
            required: questionConfig?.mandatory,
            validate: (value: string): string | boolean => {
              return validateEmailTypeQuestion(questionConfig, value, {
                name: t('email'),
              })
            },
          }}
          render={({ onChange, onBlur, value }) => {
            return (
              <InputField
                onChange={handleChange(onChange)}
                onBlur={onBlur}
                disabled={disabled}
                className={classes.shortAndLongInputField}
                value={value}
                error={getInputErrorMessage(errors, questionId)}
                placeholder={placeholderText}
                compact
                hideLabel
                withErrorSpace
                type='email'
              />
            )
          }}
        />
      )
    case UserQuestionType.Icd10Classification:
      return (
        <Controller
          name={questionId}
          control={control}
          defaultValue=''
          rules={{ required: questionConfig?.mandatory }}
          render={({ onChange, onBlur, value }) => {
            const options = getPreviewableIcd10Codes()
            const comboOptions: Array<OptionProps> = (options ?? []).map(
              option => ({
                label: `${option[0]} – ${option[1]}`,
                value: `${option[0]}|${option[1]}`,
              }),
            )
            const selectedOption: OptionProps | undefined = comboOptions.find(
              option => option.value.toString() === value.toString(),
            )
            return (
              <ComboBox
                id={questionId}
                key={questionId}
                options={comboOptions}
                compact
                onChange={(option: Array<OptionProps> | OptionProps | null) => {
                  if (!Array.isArray(option) && option) {
                    handleChange(onChange)(option.value)
                  }
                }}
                onBlur={onBlur}
                disabled={disabled}
                selectedOption={selectedOption}
                placeholder={placeholderText}
                error={!isNil(getInputErrorMessage(errors, questionId))}
                errorMessage={getInputErrorMessage(errors, questionId)}
                inputStyle={{ marginBottom: spacing.xxs }}
              />
            )
          }}
        />
      )
    default:
      return null
  }
}
